import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class ModTitle extends React.Component {

    render() {
        let {bgText, conText} = this.props;
        return (
            <div className={'ModTitle'}>
                <div className={'bg-text'}>
                    {bgText}
                </div>
                <div className={'con-text'}>
                    {conText}
                </div>
            </div>
        );
    }
}

ModTitle.propTypes = {
    bgText: PropTypes.string,
    conText: PropTypes.string
};