import React from 'react';
import PropTypes from 'prop-types';
import ImgTextMod from '../ImgTextMod';
import './index.scss';
import { isWap } from '../../util';
export default class SlidePlan extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            num: 0
        };
    }

    componentDidMount() {
    }

    render() {
        const isWapStatus = isWap();
        let data = this.props.data;
        console.log(data);
        let num = this.state.num;
        let length = data.length;
        let navDom = document.querySelectorAll('.sp-nav div')[num];
        let navLeft = 0;
        let navWidth = 0;

        if (navDom) {
            if (num === 0) {
                navLeft = 0;
            } else {
                navLeft = document.querySelectorAll('.sp-nav div')[num].offsetLeft;
                navWidth = document.querySelectorAll('.sp-nav div')[num].offsetWidth;

                let s = (760 - navWidth) * 0.5;

                navLeft = navLeft - 80 - s;
            }
        }

        return (
            <>
            {
                isWapStatus ? <div className='mobile-slide-plan'>
                    {
                        data.map((item, index) => {
                            return <div className='mobile-slide-item' key={index}>
                                <a
                                    className='item-link'
                                    href={`/fqz/page/plan/${item.aid}`}
                                    target={'_blank'}
                                >
                                    <img src={'/fqz/uploads/' + item.pic} alt='百度反诈'/>
                                </a>
                                <p className='item-title'>{item.title}</p>
                                <p className='item-subtitle'>{item.subtitle}</p>
                            </div>;

                        })
                    }
                </div>
                :
                    <div className={'SlidePlan'}>
                        <div
                            className={num <= 0 ? 'arrow-left' : 'arrow-left on'}
                            onClick={() => {
                                this.setState({
                                    num: num - 1
                                });
                            }}
                        />
                        <div
                            className={(num + 1) >= length ? 'arrow-right' : 'arrow-right on'}
                            onClick={() => {
                                this.setState({
                                    num: num + 1
                                });
                            }}
                        />
                        <div className={'sp-nav-mod'}>
                            <div
                                className={'sp-nav'}
                                style={{left: `${navLeft === 0 ? 70 : -navLeft}px`}}
                            >
                                {
                                    data.map((item, index) => {
                                        // bca-disable-line
                                        /* bca-disable */
                                        return <div
                                            className={index === num ? 'on' : ''}
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: item.title
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    num: index
                                                });
                                            }}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                        <div className={'sp-slide'}>
                            <div
                                className={'con'}
                                style={{left: `-${num * 1020}px`}}
                            >
                                {
                                    data.map((item, index) => {
                                        return <a
                                            className={'SlidePlan-item'}
                                            key={index}
                                            href={`/fqz/page/plan/${item.aid}`}
                                            target={'_blank'}
                                        >
                                            <ImgTextMod
                                                type={'LR'}
                                                imgUrl={'/fqz/uploads/' + item.pic}
                                                title={item.title}
                                                text={item.subtitle}
                                            />
                                        </a>;
                                    })
                                }
                            </div>
                        </div>
                        <div className={'sp-nav-bot'}>
                            {
                                data.map((item, index) => {
                                    return <div className={index === num ? 'on' : ''} key={index}/>;
                                })
                            }
                        </div>
                    </div>
            }
            </>

        );
    }
}

SlidePlan.propTypes = {
    data: PropTypes.array
};