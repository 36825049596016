/**
 * @file index
 * @author WangZhuang
 */

import * as home from './home';
import '../rem';

export default Object.assign(
    {},
    home
);
