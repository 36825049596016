import React from 'react';
import ModTitle from '../../components/ModTitle';
import './index.scss';

export default class Alliance extends React.Component {
    render() {
        let off1 = false; // 指导公安：暂无
        let off2 = false; // 新闻媒体：暂无
        return (
            <div className={'alliance'}>
                {
                    off1
                        ? <div className={'bai'}>
                            <div className={'section'}>
                                <ModTitle bgText={'PUBLIC SECURITY'} conText={'指导公安'}/>
                                <div className="alliance-mod">
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                </div>
                            </div>
                        </div>
                        : null
                }

                <div className={'bai'}>
                    <div className={'section'}>
                        <ModTitle bgText={'ENTERPRISE'} conText={'合作企业'}/>
                        <div className="alliance-mod">
                            <div className="alliance-item">
                                <img src={require('../../asset/img/alliance/lenovo_265.png')} alt="lenovo_265"/>
                            </div>
                            <div className="alliance-item">
                                <img src={require('../../asset/img/alliance/vivo_265.png')} alt="vivo_265"/>
                            </div>
                            <div className="alliance-item">
                                <img src={require('../../asset/img/alliance/xiaomi_265.png')} alt="xiaomi_265"/>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    off2
                        ? <div className={'bai'}>
                            <div className={'section'}>
                                <ModTitle bgText={'MEDIA'} conText={'新闻媒体'}/>
                                <div className="alliance-mod">
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                    <div className="alliance-item"></div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}
