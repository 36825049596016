import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import GoodMod from './GoodMod';
import Banner from '../../components/Banner';
import ModTitle from '../../components/ModTitle';
import SlidePlan from '../../components/SlidePlan';
import ImgTextMod from '../../components/ImgTextMod';
import PageLInk from '../../components/PageLInk';
import {fetchFqzArticles} from '../../actions/homeAction';

import './index.scss';

const TIMES_TYPE = {
    INTERCEPT: 'interceptTimes',
    INSPECT: 'inspectTimes',
    OFFLINE: 'offlineTimes',
    PREDICT: 'predictTimes',
    BAN: 'banTimes',
};

class Home extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            fqzArticles: {},
            articlesFA: {},
            interceptTimes: 0,
            inspectTimes: 0,
            offlineTimes: 0,
            predictTimes: 0,
            banTimes: 0,
            intervalIDList: [],
        };
    }

    intervalTemplate = (attributeName, reachNumber, eachAdd) => {
        let num = 0;
        var interval = setInterval(function () {
            num += eachAdd;
            this.setState({
                [attributeName]: num,
            });
            if (num === eachAdd)
            {
                let newIntervalIDList = [...this.state.intervalIDList];
                newIntervalIDList.push(interval);
                this.setState({
                    intervalIDList: newIntervalIDList,
                });
            } else if (num > reachNumber - eachAdd) {
                this.setState(
                    {
                        [attributeName]: reachNumber,
                    }
                );
                clearInterval(interval);
                let newIntervalIDList = [...this.state.intervalIDList];
                newIntervalIDList.pop(interval);
                this.setState({
                    intervalIDList: newIntervalIDList,
                });
            }
        }.bind(this), 10);
    };

    componentDidMount() {
        this.props.fetchFqzArticles({ // 解决方案
            category: 'solution'
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    articlesFA: data.message
                });
            }
        });

        this.props.fetchFqzArticles({ // 安全新闻
            category: 'news',
            pagesize: 3
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    fqzArticles: data.message
                });
            }
        });

        this.intervalTemplate(TIMES_TYPE.INTERCEPT, 1000, 6);
        this.intervalTemplate(TIMES_TYPE.INSPECT, 2512.4, 12);
        this.intervalTemplate(TIMES_TYPE.OFFLINE, 1038, 6);
        this.intervalTemplate(TIMES_TYPE.PREDICT, 1777, 10);
        this.intervalTemplate(TIMES_TYPE.BAN, 10000, 60);
    }

    componentWillUnmount() {
        this.state.intervalIDList.forEach(id => {
            clearInterval(id);
        });
    }

    render() {
        let {fqzArticles, articlesFA, inspectTimes, banTimes, offlineTimes, predictTimes, interceptTimes} = this.state;

        return (
            <div className={'home'}>
                <div className={'pc-banner'}>
                    <Banner
                        title={'百度安全反欺诈中心'}
                        text={'百度安全反欺诈中心是集诈骗行为举报、情报共享、全网欺诈风险网站搜索拦截、防骗知识宣传普及、'
                        + '黑产联合打击为一体的反欺诈综合安全服务平台，由百度安全推出。平台以人工智能、大数据为核心技术，基'
                        + '于百度在互联网20年安全实践的总结和提炼，联合公安、企业、媒体等多方力量，共筑反诈安全防线，进一步'
                        + '提升用户防骗安全意识、普及防骗知识、从源头阻断网络诈骗触达用户、持续对抗不断升级的网络黑产，营造晴'
                        + '朗的网络空间环境。'}
                    />
                </div>
                <div className={'mobile-banner'}>
                    <Banner
                        title={'百度安全反欺诈中心'}
                        text={'百度安全反欺诈中心是集诈骗行为举报、情报共享、全网欺诈风险网站搜索拦截、防骗知识宣传普及、'
                        + '黑产联合打击为一体的反欺诈综合安全服务平台，由百度安全推出。'}
                    />
                </div>

                <div className={'hui mod4'}>
                    <div className={'section'}>
                        <ModTitle bgText={'ACHIEVEMENTS'} conText={'反欺诈中心成果'}/>
                        <div className={'flex-jus-con'}>
                            <div>
                                <div className={'title'}>安全-拦截恶意网页</div>
                                <div className={'blue-text'}>{interceptTimes}亿</div>
                                <div className={'text'}>拦截恶意网页总量</div>
                            </div>
                            <div>
                                <div className={'title'}>安全-安全态势感知</div>
                                <div className={'blue-text'}>{inspectTimes}万</div>
                                <div className={'text'}>全网新增恶意网页检出</div>
                            </div>
                            <div>
                                <div className={'title'}>大搜-维护公民权益</div>
                                <div className={'blue-text page-res'}>{offlineTimes}万</div>
                                <div className={'text'}>下线涉嫌违法不良网站</div>
                            </div>
                        </div>
                        <div className={'flex-jus-con'}>
                            <div>
                                <div className={'title'}>服务体验-前置预测潜在风险</div>
                                <div className={'blue-text'}>{predictTimes}位</div>
                                <div className={'text'}>劝阻网民被骗</div>
                            </div>
                            <div>
                                <div className={'title'}>账号-抵制不良信息</div>
                                <div className={'blue-text page-res'}>{banTimes}个</div>
                                <div className={'text'}>封禁涉及违法相关账号</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bai'}>
                    <div className={'section'}>
                        <ModTitle bgText={'MISSION'} conText={'反欺诈中心功能'}/>
                        <div className={'mission-mod'}>
                            <ImgTextMod
                                className='img-text-mod-mobile'
                                type={'TB'}
                                title={'欺诈风险拦截'}
                                text={'基于百度大数据分析与内容网站安全识别，感知欺诈攻击风险'}
                                imgUrl={require('../../asset/img/home/ganzhi.png')}
                                icon={require('../../asset/img/home/ic-ganzhi.svg')}
                            />
                            <ImgTextMod
                                type={'TB'}
                                title={'网络黑产打击'}
                                text={'协助各地公安提供威胁情报与技术支持，助力黑产打击'}
                                imgUrl={require('../../asset/img/home/daji.png')}
                                icon={require('../../asset/img/home/ic-daji.svg')}
                            />
                            <ImgTextMod
                                type={'TB'}
                                title={'安全宣传普及'}
                                text={'多方位解析欺诈案例，提高网民防范意识'}
                                imgUrl={require('../../asset/img/home/jingshi.png')}
                                icon={require('../../asset/img/home/ic-jingshi.svg')}
                            />
                            <ImgTextMod
                                type={'TB'}
                                title={'反诈能力产品'}
                                text={'TOC/TOB解决方案，阻断欺诈风险，保护网络安全'}
                                imgUrl={require('../../asset/img/home/chanpin.png')}
                                icon={require('../../asset/img/home/ic-chanpin.svg')}
                            />
                        </div>
                    </div>
                </div>

                <div className={'hui'}>
                    <div className={'section'}>
                        <ModTitle bgText={'SOLUTION'} conText={'反欺诈解决方案'}/>
                        <div className={'plan-mod'}>
                            <div>
                                {
                                    !this.isEmptyobj(articlesFA)
                                        ? <SlidePlan data={articlesFA.data}/>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bai'}>
                    <div className={'section'}>
                        <ModTitle bgText={'ADVANTAGE'} conText={'反欺诈中心优势'}/>
                        <div className={'home-good'}>
                            <GoodMod
                                title={<span>20年互联网安全<br/>实践</span>}
                                icon={require('../../asset/img/goodMod/icon-shijian.svg')}
                                text={<span>Best Practice in Security for<br/>20 Years</span>}
                            />
                            <GoodMod
                                title={<span>全网威胁情报感知<br/>分析平台</span>}
                                icon={require('../../asset/img/goodMod/icon-pingtai.svg')}
                                text={<span>Threat Intelligence Perception<br/>and Analysis Platform</span>}
                            />
                            <GoodMod
                                title={<span>AI反欺诈风险拦截<br/>系统</span>}
                                icon={require('../../asset/img/goodMod/icon-xitong.svg')}
                                text={<span>AI Based Anti-Fraud Risk<br/>Interception System</span>}
                            />
                            <GoodMod
                                title={<span>政企联动黑产溯源<br/>打击技术</span>}
                                icon={require('../../asset/img/goodMod/icon-jishu.svg')}
                                text={<span>Black Industry Tracing and<br/>Attacking Technology</span>}
                            />
                        </div>
                    </div>
                </div>

                <div className={'bai mod5'}>
                    <div className={'section'}>
                        <ModTitle bgText={'COURSE'} conText={'反欺诈中心历程'}/>
                        <div className={'mod5-box'}>
                        <div className={'mod5-info'}>
                            <div>
                                疫情期间与公安联合打击假冒HPV疫苗优先接种的诈骗团伙，共拦截<span>200万</span>条订单，抓获多名犯罪嫌疑人
                            </div>
                            <div>
                                与公安联合打击黑产工具，抓获1人，起获2款作弊程序，突破反爬破解收录<span>3000w+</span>余次
                            </div>
                            <div>
                                与重庆公安部门联合重点案件打击
                            </div>
                            <div>
                                清理百度搜索涉及网络电信诈骗<br/>
                                有害网址链接达到<span>1.2亿</span>条
                            </div>
                            <div>
                                查处近<span>4W</span>个非法网站，抓获<br/>
                                了犯罪团伙近百人
                            </div>
                            <div>
                                打击利用运营商漏洞窃取用户<br/>
                                隐私信息站点<span>6W+</span>
                            </div>
                            <div>
                                与<span>200</span>家公安部门建立合作<br/>
                                查封下线<span>10W+</span>家非法站点
                            </div>
                        </div>
                        <div className={'mod5-ing'}>
                            <div>
                                2022
                                <div className={'mod5-ing-c'}>反诈中心向打击组发来<span>感谢信</span></div>
                            </div>
                            <div/>
                            <div>
                                2021
                            </div>
                            <div/>
                            <div>
                                2020
                                <div className={'mod5-ing-c'}>下线<span>2.4W+</span>冒充游戏平台非法网址，抓获犯罪嫌疑人8人</div>
                            </div>
                            <div/>
                            <div>
                                2019
                            </div>
                            <div/>
                            <div>
                                2018
                                <div className={'mod5-ing-c'}>协助公安破获国内首例新型用户隐私<span>手机访客营销</span>黑产</div>
                            </div>
                            <div/>
                            <div>
                                2017
                            </div>
                            <div/>
                            <div>
                                2016
                                <div className={'mod5-ing-c'}>成立<span>互联网黑产打击</span>团队</div>
                            </div>
                            <div/>
                        </div>
                        </div>
                    </div>
                </div>

                <div className={'hui mod6'}>
                    <div className={'section1'}>
                        <ModTitle bgText={'JOURNALISM'} conText={'安全新闻'}/>
                        <div className={'mod6-con'}>
                            {
                                !this.isEmptyobj(fqzArticles)
                                    ? fqzArticles.data.map((item, index) => {
                                        return <PageLInk
                                            title={item.title}
                                            des={item.subtitle}
                                            time={item.publish_time}
                                            imgUrl={'/fqz/uploads/' + item.pic}
                                            key={index}
                                            author={item.author}
                                            linkUrl={`/fqz/page/news/${item.aid}`}
                                        />;
                                    })
                                    : null
                            }
                        </div>
                        <a className={'more'} href={'/fqz/news'}>
                            查看更多<i/>
                        </a>
                    </div>
                </div>

                <div className="hui">
                    <div className="section2">
                        <ModTitle bgText={'REPORT'} conText={'举报链接'} />
                        <div className="custom-tag">
                            <div className="tag-text">
                                <p class="big-text">
                                    <a href="http://cyberpolice.mps.gov.cn/" target="_blank">
                                        网络违法犯罪举报网站
                                    </a>
                                </p>
                                <p class="small-text">
                                    不具备现场、紧急报警的受理功能，如您的情况紧急，请立即拨打报警电话“110”
                                </p>
                                <p class="spacer"></p>
                                <p class="big-text">
                                    <a href="https://www.12377.cn/" target="_blank">
                                        中国互联网举报中心
                                    </a>
                                </p>
                                <p class="small-text">
                                    中国互联网违法和不良信息举报中心是中央网络安全和信息化(国家互联网信息办公室)直属事业单位
                                </p>
                                <p class="small-text">
                                    举报电话：12377
                                </p>
                                <p class="spacer"></p>
                                <p class="big-text">
                                    反电信网络诈骗专用号码
                                </p>
                                <p class="small-text">
                                    96110是反电信网络诈骗专用号码，专门用于对群众的预警劝阻和防范宣传等工作
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mod7">
                    <img src={require('../../asset/img/home/ad.png')} alt=""/>
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFqzArticles: params => fetchFqzArticles(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);