import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ModTitle from '../../components/ModTitle';
import PageLInk from '../../components/PageLInk';
import {Pagination} from 'antd';
import {fetchFqzArticles} from '../../actions/homeAction';
import './index.scss';

class News extends React.Component {

    constructor(prop) {
        super(prop);
        this.state = {
            fqzArticles: {},
            page: 1
        };
    }

    componentDidMount() {
        this.articlesFn();
    }

    articlesFn = () => {
        this.props.fetchFqzArticles({
            category: 'news',
            pagesize: 5,
            page: this.state.page
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    fqzArticles: data.message
                });
            }
        });

    };

    onChange = page => {
        this.setState({
            page: page
        }, () => {
            this.articlesFn();
        });
    };

    render() {
        let fqzArticles = this.state.fqzArticles;

        return (
            <div className={'news hui'}>
                <div className={'news-con'}>
                    <ModTitle bgText={'JOURNALISM'} conText={'安全新闻'}/>
                    <div className={'news-con-mod'}>
                        {
                            !this.isEmptyobj(fqzArticles)
                                ? fqzArticles.data.map((item, index) => {
                                    return <PageLInk
                                        title={item.title}
                                        des={item.subtitle}
                                        time={item.publish_time}
                                        imgUrl={'/fqz/uploads/' + item.pic}
                                        key={index}
                                        author={item.author}
                                        linkUrl={`/fqz/page/news/${item.aid}`}
                                    />;
                                })
                                : null
                        }
                    </div>
                    {
                        !this.isEmptyobj(fqzArticles)
                            ? <Pagination
                                defaultCurrent={1}
                                defaultPageSize={5}
                                current={fqzArticles.current_page || 1}
                                total={fqzArticles.total || 1}
                                size={'small'}
                                onChange={this.onChange}
                            />
                            : null
                    }
                </div>
            </div>
        );
    }
}

News.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFqzArticles: params => fetchFqzArticles(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);