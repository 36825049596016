import React, {Component} from 'react';
import './index.scss';
import { isWap } from '../../util';
export default class Nav extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isMenuShow: false
        };
    }
    /**
     * @description 渲染导航组件，包括左侧Logo、菜单和右侧恶意线索举报按钮
     *
     * @returns {JSX.Element} 返回 JSX 元素，包括导航组件的 DOM 结构
     */
    render() {
        const isWapStatus = isWap();
        let navArr = [
            {
                url: '/fqz',
                val: '首页'
            },
            {
                url: '/fqz/news',
                val: '新闻资讯',
                key: 'news'
            },
            {
                url: '/fqz/plan',
                val: '反诈解决方案',
                key: 'plan'
            },
            // {
            //     url: '/fqz/alliance',
            //     val: '反诈联盟',
            //     key: 'alliance'
            // },
            {
                url: '/fqz/classroom',
                val: '防骗小课堂',
                key: 'classroom'
            }
        ];

        return (
            <div className={'Nav'}>
                <div className={'nav-left'}>
                    <a className={'logo'} href={'/fqz'}>
                    </a>
                    <div>
                        {this.state.isMenuShow &&
                            <ul className='menu-ul'>
                                {
                                    navArr.map((item, index) => {
                                        let pathname = window.location.pathname;
                                        let active = '';
                                        if (index === 0) {
                                            active = pathname === item.url || pathname === '/fqz/' ? 'on' : '';
                                        } else {
                                            active = pathname.indexOf(item.key) !== -1 ? 'on' : '';
                                        }
                                        // return <li>{item.val}</li>;
                                        return <li>
                                            <a
                                                href={item.url}
                                                className={active}
                                                key={index}
                                            >
                                                {item.val}
                                            </a>
                                        </li>;
                                    })
                                }
                            </ul>
                        }
                    </div>

                    {
                        !isWapStatus
                            ? <div className={'nav-list'}>
                                {
                                    navArr.map((item, index) => {
                                        let pathname = window.location.pathname;
                                        let active = '';
                                        if (index === 0) {
                                            active = pathname === item.url || pathname === '/fqz/' ? 'on' : '';
                                        } else {
                                            active = pathname.indexOf(item.key) !== -1 ? 'on' : '';
                                        }
                                        return <a
                                            href={item.url}
                                            className={active}
                                            key={index}
                                        >
                                            {item.val}
                                        </a>;
                                    })
                                }
                            </div>
                            : <div
                                className='nav-top'
                                onClick={() => {
                                    this.state.isMenuShow ? this.setState({isMenuShow: false})
                                    : this.setState({isMenuShow: true});
                                    console.log(this.state.isMenuShow);
                            }}>
                            </div>
                    }
                </div>
                <a href={'https://bsb.baidu.com/report'} target={'_blank'} className='jubao'>恶意线索举报</a>
            </div>
        );
    }
}