import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class PageList extends React.Component {

    render() {
        const {title, des, imgUrl, linkUrl, time, author} = this.props;
        return (
            <div className={'pageList'}>
                <div className={'img-mod'}>
                    <img src={imgUrl} alt=""/>
                </div>
                <div className={'right'}>
                    <div className={'title'}>
                        {title}
                    </div>
                    <div className={'des'}>
                        <span>{time}</span>
                        <span>作者：{author}</span>
                    </div>
                    <div className={'brief'}>
                        {des}
                    </div>
                    <div className={'bot'}>
                        <a href={linkUrl} target={'_blank'} className={'btn'}>
                            查看详情
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

PageList.propTypes = {
    title: PropTypes.string,
    des: PropTypes.string,
    img: PropTypes.string,
    url: PropTypes.string,
    time: PropTypes.string,
    author: PropTypes.string
};