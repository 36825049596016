/**
 * @file 扩展插件
 * @author WangZhuang
 */
import axios from 'axios';
import {Component} from 'react';

export function rcAjax(type, url, param, data, callback) {
    return axios({
        method: type,
        url: url,
        params: param,
        data: data
    })
        .then(response => {
            callback(response);
            return response.data;
        })
        .catch(error => {
            callback(error.response);
        });
}

export function extendComponent() {
    return function () {
        Component.prototype.isEmptyobj = function (obj) { // 判断是否为空对象
            for (let key in obj) {
                return false;
            }
            return true;
        };
    };
}
const inBrowser = typeof window !== 'undefined';
const WXEnvironment = window.WXEnvironment;
const inWeex = typeof WXEnvironment !== 'undefined' && !!WXEnvironment.platform;
const weexPlatform = inWeex && WXEnvironment.platform.toLowerCase();
export const UA = inBrowser && window.navigator.userAgent.toLowerCase();
export let isAndroid = (UA && UA.indexOf('android') > 0) || (weexPlatform === 'android');
export let isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (weexPlatform === 'ios');
export let isSymbian = (UA && UA.indexOf('symbian') > 0);
export function isWap() {
    return isAndroid || isIOS || isSymbian;
}
