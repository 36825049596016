import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchArticlesAid} from '../../actions/homeAction';
import './index.scss';

class PageInfo extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            articles: {}
        };
    }

    componentDidMount() {
        this.props.fetchArticlesAid(this.props.match.params.id).then(data => { // 文章详情
            if (data.code === 200) {
                this.setState({
                    articles: data.message
                });
            }
        });
    }

    render() {
        let articles = this.state.articles;
        return (
            <div className={'pageInfo'}>
                {
                    !this.isEmptyobj(articles)
                        ? <div>
                            <div className={'page-title'}>
                                {articles.title}
                            </div>
                            <div className={'page-des'}>
                                {articles.publish_time}
                                <span>作者：{articles.author}</span>
                            </div>
                            <div
                                className={'page-con'}
                                dangerouslySetInnerHTML={{
                                    __html: articles.content
                                }}
                            />
                            <div
                                className={'page-btn'}
                                onClick={() => {
                                    window.location.href = `/fqz/${this.props.match.params.type}`;
                                }}
                            >
                                返回目录
                            </div>
                        </div>
                        : null
                }


            </div>
        );
    }
}

PageInfo.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchArticlesAid: params => fetchArticlesAid(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageInfo);