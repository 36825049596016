import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ModTitle from '../../components/ModTitle';
import PageLInk from '../../components/PageLInk';
import {Pagination} from 'antd';
import {fetchFqzArticles} from '../../actions/homeAction';
import './index.scss';

class ClassroomMore extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            articlesBD: {},
            page: 1
        };
    }

    componentDidMount() {
        this.articlesFn();
    }

    articlesFn = () => {
        this.props.fetchFqzArticles({
            category: 'anti',
            pagesize: 5,
            page: this.state.page
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    articlesBD: data.message
                });
            }
        });
    };

    onChange = page => {
        this.setState({
            page: page
        }, () => {
            this.articlesFn();
        });
    };

    render() {
        let articlesBD = this.state.articlesBD;
        return (
            <div className={'classroom'}>
                <div className={'hui'}>
                    <div className={'section case-mod'}>
                        <ModTitle bgText={'CASE'} conText={'防骗宝典'}/>
                        <div className={'news-con-mod'}>
                            {!this.isEmptyobj(articlesBD)
                                ? articlesBD.data.map((item, index) => {
                                    return <PageLInk
                                        title={item.title}
                                        des={item.subtitle}
                                        time={item.publish_time}
                                        imgUrl={'/fqz/uploads/' + item.pic}
                                        key={index}
                                        author={item.author}
                                        linkUrl={`/fqz/page/classroomMore/${item.aid}`}
                                    />;
                                })
                                : null
                            }
                        </div>
                        {
                            !this.isEmptyobj(articlesBD)
                                ? <Pagination
                                    defaultCurrent={1}
                                    defaultPageSize={5}
                                    current={articlesBD.current_page || 1}
                                    total={articlesBD.total || 1}
                                    size={'small'}
                                    onChange={this.onChange}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ClassroomMore.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFqzArticles: params => fetchFqzArticles(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomMore);