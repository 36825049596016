import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class ImgTextMod extends React.Component {
    render() {
        let {imgUrl, title, text, icon, type, btn} = this.props;
        let dom = '';

        if (type === 'TB') {
            dom = <div className={'ImgTextMod TB'}>
                <div className={'mod-lr-top'}>
                    <img src={imgUrl} alt=""/>
                </div>
                <div className={'mod-con'}>
                    <div className={'mod-con-title'}>
                        {title}
                    </div>
                    <div className={'mod-text'}>
                        {text}
                    </div>
                    {
                        btn
                            ? <a
                                className={'btnStrong'}
                                href={btn.url}
                                target={'_blank'}
                            >
                                {btn.val}
                            </a>
                            : null
                    }
                </div>
                {
                    icon ? <div className={'mod-icon'} style={{backgroundImage: `url(${icon})`}}/> : null
                }
            </div>;
        }

        if (type === 'LR') {
            dom = <div className={'ImgTextMod LR'}>
                <div className={'mod-lr-top'}>
                    <img src={imgUrl} alt=""/>
                </div>
                <div className={'mod-con'}>
                    <div className={'mod-con-title'}>
                        {title}
                    </div>
                    <div className={'mod-text'}>
                        {text}
                    </div>
                </div>
            </div>;
        }

        return (dom);
    };
}

ImgTextMod.propTypes = {
    icon: PropTypes.string, // 图标
    text: PropTypes.string, // 副文本
    type: PropTypes.string, // 方向：LR=左右 TB=上下
    title: PropTypes.any,   // 标题
    btn: PropTypes.object,     // btn、链接
    imgUrl: PropTypes.string // img
};