import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {extendComponent} from './util';
import * as serviceWorker from './serviceWorker';
import reducers from './reducers';

const todoApp = combineReducers(reducers);
const store = createStore(todoApp);
extendComponent()();

ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <BrowserRouter basename="/fqz">
            <App/>
        </BrowserRouter>
    </BrowserRouter>
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
