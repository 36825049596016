import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ModTitle from '../../components/ModTitle';
import ImgTextMod from '../../components/ImgTextMod';
import PageLInk from '../../components/PageLInk';
import {Pagination} from 'antd';
import {fetchFqzArticles} from '../../actions/homeAction';
import './index.scss';

class Classroom extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            articlesBD: {},
            fqzArticles: {},
            page: 1
        };
    }

    componentDidMount() {
        this.props.fetchFqzArticles({
            category: 'anti',
            pagesize: 3
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    articlesBD: data.message
                });
            }
        });
        this.articlesFn();
    }

    articlesFn = () => {
        this.props.fetchFqzArticles({
            category: 'case',
            pagesize: 5,
            page: this.state.page
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    fqzArticles: data.message
                });
            }
        });

    };

    onChange = page => {
        this.setState({
            page: page
        }, () => {
            this.articlesFn();
        });
    };


    render() {
        let {articlesBD, fqzArticles} = this.state;
        return (
            <div className={'classroom'}>
                <div className={'bai'}>
                    <div className={'section'}>
                        <ModTitle bgText={'KNACK'} conText={'防骗宝典'}/>
                        <div className={'mission-mod'}>
                            {!this.isEmptyobj(articlesBD)
                                ? articlesBD.data.map((item, index) => {
                                    return <ImgTextMod
                                        key={index}
                                        type={'TB'}
                                        title={item.title}
                                        text={item.subtitle}
                                        imgUrl={'/fqz/uploads/' + item.pic}
                                        btn={{val: '查看详情', url: `/fqz/page/classroom/${item.aid}`}}
                                    />;
                                })
                                : null

                            }
                        </div>
                        <a className="knack-btn btn" href={'/fqz/classroomMore'}>
                            查看更多
                        </a>
                    </div>
                </div>

                <div className={'hui'}>
                    <div className={'section case-mod'}>
                        <ModTitle bgText={'CASE'} conText={'案例分析'}/>
                        <div className={'news-con-mod'}>
                            {
                                !this.isEmptyobj(fqzArticles)
                                    ? fqzArticles.data.map((item, index) => {
                                        return <PageLInk
                                            title={item.title}
                                            des={item.subtitle}
                                            time={item.publish_time}
                                            imgUrl={'/fqz/uploads/' + item.pic}
                                            key={index}
                                            author={item.author}
                                            linkUrl={`/fqz/page/classroom/${item.aid}`}
                                        />;
                                    })
                                    : null
                            }
                        </div>
                        {
                            !this.isEmptyobj(fqzArticles)
                                ? <Pagination
                                    defaultCurrent={1}
                                    defaultPageSize={5}
                                    current={fqzArticles.current_page || 1}
                                    total={fqzArticles.total || 1}
                                    size={'small'}
                                    onChange={this.onChange}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Classroom.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFqzArticles: params => fetchFqzArticles(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Classroom);