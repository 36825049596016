import React from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './containers/home';
import News from './containers/news';
import Alliance from './containers/alliance';
import Plan from './containers/plan';
import Classroom from './containers/classroom';
import ClassroomMore from './containers/classroomMore';
import PageInfo from './containers/pageInfo';
import './app.scss';

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            conMinHeight: 0
        };
    }

    componentDidMount() {
        this.setState({
            conMinHeight: window.innerHeight - this.footer.offsetHeight
        });
    }

    render() {
        return (
            <div className="App">
                <Nav/>
                <div style={{minHeight: `${this.state.conMinHeight}px`}}>
                    <Switch>
                        <Route exact path={'/'} component={Home}/>
                        <Route exact path={'/news'} component={News}/>
                        <Route exact path={'/alliance'} component={Alliance}/>
                        <Route exact path={'/plan'} component={Plan}/>
                        <Route exact path={'/classroom'} component={Classroom}/>
                        <Route exact path={'/classroomMore'} component={ClassroomMore}/>
                        <Route exact path={'/page/:type/:id'} component={PageInfo}/>
                    </Switch>
                </div>
                <div ref={el => this.footer = el}>
                    <Footer/>
                </div>
            </div>
        );
    }

}

export default App;
