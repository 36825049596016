/**
 * @file rem.js
 */
 (function (designWidth, maxWidth) { // 淘宝移动端的rem计算方式
    var doc = document;
    var win = window;
    var docEl = doc.documentElement;
    var metaEl;
    var metaElCon;
    var remStyle = document.createElement('style');
    var tid;

    function refreshRem() {
        var width = docEl.getBoundingClientRect().width;
        if (!maxWidth) {
            maxWidth = 540;
        }
        if (width > maxWidth) { // 限制在540的屏幕下，这样100%就跟10rem不一样了
            width = maxWidth;
        }
        // 设计稿按1080px输出后，即可直接使用设计稿中的【值 / 100 rem】即可
        var rem = width * 100 / designWidth;
        // bca-disable-line
        remStyle.innerHTML = 'html{font-size:' + rem + 'px !important;}';
    }

    // 设置 viewport ，有的话修改 没有的话设置
    metaEl = doc.querySelector('meta[name="viewport"]');
    // 20190104修改：增加 viewport-fit=cover ，用于适配iphoneX
    metaElCon = 'width=device-width,initial-scale=1,maximum-scale=1.0,user-scalable=no,viewport-fit=cover';
    if (metaEl) {
        metaEl.setAttribute('content', metaElCon);
    } else {
        metaEl = doc.createElement('meta');
        metaEl.setAttribute('name', 'viewport');
        metaEl.setAttribute('content', metaElCon);
        if (docEl.firstElementChild) {
            docEl.firstElementChild.appendChild(metaEl);
        } else {
            var wraps = doc.createElement('div');
            wraps.appendChild(metaEl);
            // bca-disable-line
            doc.write(wraps.innerHTML);
            wraps = null;
        }
    }

    // 要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
    refreshRem();

    if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
    } else {
        var wrap = doc.createElement('div');
        wrap.appendChild(remStyle);
        // bca-disable-line
        doc.write(wrap.innerHTML);
        wrap = null;
    }

    win.addEventListener('resize', function () {
        clearTimeout(tid); // 防止执行两次
        tid = setTimeout(refreshRem, 300);
    }, false);

    win.addEventListener('pageshow', function (e) {
        if (e.persisted) { // 浏览器后退的时候重新计算
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
        }
    }, false);

    if (doc.readyState === 'complete') {
        doc.body.style.fontSize = '14px';
    } else {
        doc.addEventListener('DOMContentLoaded', function (e) {
            doc.body.style.fontSize = '14px';
        }, false);
    }
})(1080, 1080);
