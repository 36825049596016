import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ModTitle from '../../components/ModTitle';
import SlidePlan from '../../components/SlidePlan';
import ImgTextMod from '../../components/ImgTextMod';
import {fetchFqzArticles} from '../../actions/homeAction';
import './index.scss';

class Plan extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = {
            articlesFA: {}
        };
    }

    componentDidMount() {
        this.props.fetchFqzArticles({ // 解决方案
            category: 'solution'
        }).then(data => {
            if (data.code === 200) {
                this.setState({
                    articlesFA: data.message
                });
            }
        });
    }


    render() {
        let articlesFA = this.state.articlesFA;
        return (
            <div className={'plan'}>
                <div className={'hui'}>
                    <div className={'section'}>
                        <ModTitle bgText={'SOLUTION'} conText={'反欺诈解决方案'}/>
                        <div className={'plan-mod'}>
                            <div>
                                {
                                    !this.isEmptyobj(articlesFA)
                                        ? <SlidePlan data={articlesFA.data}/>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bai'}>
                    <div className={'section'}>
                        <ModTitle bgText={'CHARACTERISTIC'} conText={'反欺诈中心特色'}/>
                        <div className={'mission-mod'}>
                            <ImgTextMod
                                type={'TB'}
                                title={<span>百度安全恶意网址<br/>信息监测平台</span>}
                                text={'覆盖欺诈，违法和风险恶意网站识别，提供开放的网址安全检测结果查询平台，使网址安全一查便知。'}
                                imgUrl={require('../../asset/img/plan/pingtai.png')}
                            />
                            <ImgTextMod
                                type={'TB'}
                                title={<span>百度安全反欺诈<br/>虚假官方电话公示</span>}
                                text={'定向挖掘欺诈电话、虚假客服电话并公示，防范电信欺诈'}
                                imgUrl={require('../../asset/img/plan/gongshi.png')}
                            />
                            <ImgTextMod
                                type={'TB'}
                                title={<span>百度安全互联网<br/>黑产打击团队</span>}
                                text={'组建“互联网黑产打击”团队，协助公安严厉打击各类网络黑灰产'}
                                imgUrl={require('../../asset/img/plan/tuandui.png')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Plan.propTypes = {
    fetchFqzArticles: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFqzArticles: params => fetchFqzArticles(dispatch, params)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan);