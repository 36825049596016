/**
 * @file 首页
 * @author wangzhuang
 */

import {
    GET_FQZ_ARTICLES
} from '../actions/ActionTypes';

export function fqzArticles(state = {}, action) {
    switch (action.type) {
        case GET_FQZ_ARTICLES:
            if (action.data.is_error === 0) {
                state = action.data;
                return state;
            }
            return {};
        default:
            return state;
    }
}
