/**
 * @file 首页
 * @author wangzhuang
 */

import {rcAjax} from '../util';
import {
    GET_FQZ_ARTICLES,
    GET_ARTICLE_AID
} from './ActionTypes';

export function fetchFqzArticles(dispatch, params) {
    /**
     * page=1 页数
     * category 分类 防骗宝典：anti，安全新闻：news，诈骗案例：solution，案例分析：case
     * */
    return rcAjax('GET', '/fqz/api/v3/fqz/articles', params, {}, data => {
        dispatch({type: GET_FQZ_ARTICLES, data: data.data});
    });
}


export function fetchArticlesAid(dispatch, aid) {
    /**
     * aid=1 文章ID
     * */
    return rcAjax('GET', '/fqz/api/v3/fqz/article/' + aid, {}, {}, data => {
        dispatch({type: GET_ARTICLE_AID, data: data.data});
    });
}