import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class GoodMod extends React.Component {

    render() {
        let {title, icon, text} = this.props;
        return (
            <div className={'goodMod'}>
                <img className={'mod-img'} src={icon} alt=""/>
                <div className={'mod-title'}>
                    {title}
                </div>
                <div className={'mod-cut'}/>
                <div className={'mod-brief'}>
                    {text}
                </div>
            </div>
        );
    }
}

GoodMod.protoType = {
    title: PropTypes.node,
    icon: PropTypes.string,
    text: PropTypes.node
};