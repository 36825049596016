import React from 'react';
import './index.scss';
import AnquanLogo from '../../asset/img/footer/anquan-logo.svg';

export default class Footer extends React.Component {
    render() {
        return (
            <div className={'footer'}>
                <div className={'footer-con'}>
                    <div className={'footer-con-top'}>
                        <div className={'footer-con-left'}>
                            <img src={AnquanLogo} alt="" className={'anquanLogo'}/>
                            <div>商务咨询：400-805-4999</div>
                        </div>
                        <div className={'footer-con-right'}>
                            <div>
                                <div>新闻：</div>
                                <a href={'/fqz/news'}>安全新闻</a>
                            </div>

                            <div>
                                <div>反诈解决方案：</div>
                                <a href={'/fqz/page/plan/68'}>百度安全安全浏览解决方案</a>
                                <a href={'/fqz/page/plan/67'}>百度安全反钓鱼网站解决方案</a>
                            </div>

                            <div>
                                <div>防骗学堂：</div>
                                <a href={'/fqz/classroomMore'}>防诈骗小课堂</a>
                                <a href={'/fqz/classroom'}>案例分析</a>
                            </div>

                            <div>
                                <div>相关链接：</div>
                                <a href={'https://anquan.baidu.com/'}>百度安全</a>
                            </div>
                        </div>
                    </div>

                    <div className={'footer-con-bot'}>
                        © 2018 Baidu
                        &nbsp;<a href="https://www.baidu.com/duty/" target={'_blank'}>使用百度前必读</a>
                        &nbsp;<a href="http://help.baidu.com/" target={'_blank'}>意见反馈</a>
                        &nbsp;京ICP证030173号 京公网安备1100002000001号
                    </div>
                </div>
            </div>
        );
    }
}