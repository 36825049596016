import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { isWap } from '../../util';

export default class Banner extends Component {
    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.string
    };

    render() {
        let {title, text} = this.props;
        const isWapStatus = isWap();
        return (
            <div className={'banner'}>
                <div className={'banner-con'}>
                    <div className={'banner-title title'}>
                        {title}
                    </div>
                    {<div className={'banner-text'}>
                        {text}
                    </div>}
                </div>
            </div>
        );
    }
}